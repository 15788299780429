import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CertifiedCompaniesTotals,
  useCertifiedCompaniesTotals,
} from '../../../hooks/use-certified-companies-totals';
import { Grid } from '../../../templates/layout/layout';
import { decorativeImageAlt } from '../../attributes';
import { BlabButtonStyle, Button } from '../../buttons';

type MetricDisplay = {
  translationKey: string;
  value: number;
  colorClass: string;
};

type CommunityMetricDisplay = {
  UnifyingGoal: MetricDisplay;
  Companies: MetricDisplay;
  Workers: MetricDisplay;
  Industries: MetricDisplay;
  Countries: MetricDisplay;
};

function communityMetricsToDisplay(
  metrics: CertifiedCompaniesTotals
): CommunityMetricDisplay {
  const display: CommunityMetricDisplay = {
    UnifyingGoal: {
      translationKey: 'unifying-goal',
      value: 1,
      colorClass: 'bg-red',
    },
    Companies: {
      translationKey: 'companies',
      value: metrics.companies,
      colorClass: 'bg-blue-dark',
    },
    Workers: {
      translationKey: 'workers',
      value: metrics.workers,
      colorClass: 'bg-blue-dark',
    },
    Industries: {
      translationKey: 'industries',
      value: metrics.industries,
      colorClass: 'bg-blue-dark',
    },
    Countries: {
      translationKey: 'countries',
      value: metrics.countries,
      colorClass: 'bg-blue-dark',
    },
  };

  return display;
}

const MetricBlock = (props: { metric: MetricDisplay }): JSX.Element => {
  const { t } = useTranslation();
  const label = t(props.metric.translationKey);
  const classes = `flex flex-col items-center	py-8 text-white ${props.metric.colorClass}`;
  const value = new Intl.NumberFormat('en-us').format(props.metric.value);

  return (
    <div className={classes}>
      <div className="text-4xl font-bold">{value}</div>
      <div className="text-2xl text-center">{label}</div>
    </div>
  );
};

const LargeStatWall = (props: {
  metrics: CommunityMetricDisplay;
}): JSX.Element => {
  return (
    <div className="flex-row-gutter-3">
      <div className="flex-col-stack-3">
        <StaticImage
          src="../../../../static/images/b-crowd@2x.png"
          alt={decorativeImageAlt}
        />
        <MetricBlock metric={props.metrics.Workers} />
        <StaticImage
          src="../../../../static/images/sd-g-thats-a-cool-box@2x.png"
          alt={decorativeImageAlt}
        />
      </div>
      <div className="flex-col-stack-3">
        <MetricBlock metric={props.metrics.UnifyingGoal} />
        <StaticImage
          src="../../../../static/images/b-microphone@2x.png"
          alt={decorativeImageAlt}
        />

        <MetricBlock metric={props.metrics.Companies} />
        <MetricBlock metric={props.metrics.Industries} />
      </div>
      <div className="flex-col-stack-3">
        <StaticImage
          src="../../../../static/images/b-decal@2x.png"
          alt={decorativeImageAlt}
        />
        <MetricBlock metric={props.metrics.Countries} />
        <StaticImage
          src="../../../../static/images/b-hilarious@2x.png"
          alt={decorativeImageAlt}
        />
      </div>
    </div>
  );
};

const MobileStatWall = (props: {
  metrics: CommunityMetricDisplay;
}): JSX.Element => {
  return (
    <div className="flex-row-gutter-3">
      <div className="flex-col-stack-3">
        <StaticImage
          src="../../../../static/images/b-crowd@2x.png"
          alt={decorativeImageAlt}
        />
        <MetricBlock metric={props.metrics.Workers} />
        <MetricBlock metric={props.metrics.Companies} />
      </div>
      <div className="flex-col-stack-3">
        <MetricBlock metric={props.metrics.UnifyingGoal} />
        <MetricBlock metric={props.metrics.Countries} />
        <MetricBlock metric={props.metrics.Industries} />
        <StaticImage
          src="../../../../static/images/b-microphone@2x.png"
          alt={decorativeImageAlt}
        />
      </div>
    </div>
  );
};

const SupportBlabButton = (): JSX.Element => {
  const { t } = useTranslation();
  const donateURL =
    'https://donate.bcorporation.net/give/333999/#!/donation/checkout';
  return (
    <Button
      blabType={BlabButtonStyle.LargeFilledButton}
      onClick={() => window.open(donateURL, '_blank')}
    >
      {t('donate-today')}
    </Button>
  );
};

const Hero = (): JSX.Element => {
  const { t } = useTranslation();
  const totals = useCertifiedCompaniesTotals();
  const communityMetricsDisplay = communityMetricsToDisplay(totals);

  return (
    <>
      {/** Desktop */}
      <div className="hidden lg:flex col-span-6">
        <div className="flex-col-stack-3 w-80 mx-auto">
          <h1 data-testid="hero-header">{t('hero-header')}</h1>
          <p>{t('hero-tag-line-part-1')}</p>
          <p className="font-bold">{t('hero-tag-line-part-2')}</p>
          <SupportBlabButton />
        </div>
      </div>
      <div className="hidden lg:flex col-span-6">
        <LargeStatWall metrics={communityMetricsDisplay} />
      </div>

      {/** Tablet + Mobile */}
      <Grid className="lg:hidden col-span-full">
        <h1 className="col-span-full text-center" data-testid="hero-header">
          {t('hero-header')}
        </h1>
        <div className="col-span-full flex-col-stack-2">
          <div className="md:hidden flex">
            <MobileStatWall metrics={communityMetricsDisplay} />
          </div>
          <div className="hidden md:flex">
            <LargeStatWall metrics={communityMetricsDisplay} />
          </div>
          <p className="text-center">{t('hero-tag-line-part-1')}</p>
          <p className="font-bold text-center">{t('hero-tag-line-part-2')}</p>
          <SupportBlabButton />
        </div>
      </Grid>
    </>
  );
};

export default Hero;
