import React from 'react';
import { useCookieConsent } from '../hooks/use-cookie-consent';
import { CookieBanner } from './cookie-banner/cookie-banner';
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';

export const CookieConsent = () => {
  const { isVisible, accept, decline } = useCookieConsent({
    cookieName: 'gatsby-gdpr-google-tagmanager',
  });

  if (!isVisible) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 z-10">
      <CookieBanner
        title="Cookie Consent"
        description="We use cookies to give you the best possible experience on our website. By accepting, you help us improve site performance, personalize content, and assist us in our marketing efforts. You can change your preferences at any time."
        acceptLabel="Accept All Cookies"
        declineLabel="Limit Cookies"
        onAccept={() => {
          accept();
          initializeAndTrack(window.location);
        }}
        onDecline={() => {
          decline();
        }}
      />
    </div>
  );
};
