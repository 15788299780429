import React from 'react';
import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import { useTranslation } from 'react-i18next';
import Layout from '../../../templates/layout/layout';
import { StaticImage } from 'gatsby-plugin-image';
import { decorativeImageAlt } from '../../attributes';
import { useSiteMetadata } from '../../../hooks/use-site-metadata';
import Button from '../../../fiber/components/button/button';

const NotFound = (): JSX.Element => {
  const pathname = useLocation().pathname;
  const { t, i18n } = useTranslation();
  const siteMetadata = useSiteMetadata();
  const metaTagProps = {
    description: t('seo-404-description'),
    title: t('seo-404-title'),
    lang: i18n.language,
    siteUrl: siteMetadata.siteUrl,
    pathWithoutLanguage: '/',
    noIndex: true,
  };
  const isFindABCorp = pathname.includes('/find-a-b-corp/');

  return (
    <Layout metaTagProps={metaTagProps}>
      <div className="col-span-full flex flex-col items-center pb-5 lg:pb-16">
        <StaticImage
          src="../../../../static/images/impact-bot-not-found.svg"
          alt={decorativeImageAlt}
          placeholder="none"
        />

        <div className="flex flex-col gap-4 mt-6 lg:mt-12">
          <h1 className="text-2xl font-bold text-fiber-neutral-900 text-center">
            {t(
              isFindABCorp
                ? 'find-a-b-corp-not-found-title'
                : 'page-not-found-title'
            )}
          </h1>

          <p className="text-fiber-neutral-500 text-center">
            {t(
              isFindABCorp
                ? 'find-a-b-corp-not-found-description'
                : 'page-not-found-description'
            )}
          </p>

          <div className="flex flex-col justify-center items-center gap-4 lg:flex-row">
            <Button
              type="neutral"
              size="sm"
              label={t('take-me-to-homepage')}
              onClick={() => {
                navigate(`/${i18n.language}`);
              }}
            />
            <Button
              type="grey"
              size="sm"
              label={t(
                isFindABCorp
                  ? 'search-the-bcorp-directory'
                  : 'learn-more-about-bcorp'
              )}
              onClick={() => {
                navigate(
                  isFindABCorp
                    ? `/${i18n.language}/find-a-b-corp`
                    : `/${i18n.language}/movement/about-b-lab`
                );
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default NotFound;
