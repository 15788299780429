import React from 'react';

export type CookieBannerProps = {
  title: string;
  description: string;
  acceptLabel: string;
  declineLabel: string;
  onAccept: () => void;
  onDecline: () => void;
};

export function CookieBanner({
  title,
  description,
  acceptLabel,
  declineLabel,
  onAccept,
  onDecline,
}: CookieBannerProps) {
  return (
    <div
      role="dialog"
      aria-labelledby="cookieBannerTitle"
      aria-describedby="cookieBannerText"
      className="flex flex-col gap-y-32px border-t border-black bg-white p-32px"
    >
      <div>
        <h2 id="cookieBannerTitle" className="font-bold text-sm text-black">
          {title}
        </h2>
        <p id="cookieBannerText" className="text-sm text-black">
          {description}
        </p>
      </div>

      <div className="pb-20 lg:pb-0 flex flex-col items-start gap-y-16px lg:flex-row lg:gap-x-32px">
        <button
          onClick={onDecline}
          className="px-20px py-12px lg:px-24px lg:py-14px font-medium text-base lg:text-lg text-fiber-neutral-900 bg-white border border-fiber-neutral-300 rounded-md whitespace-nowrap"
        >
          {declineLabel}
        </button>
        <button
          onClick={onAccept}
          className="px-20px py-12px lg:px-24px lg:py-14px font-medium text-base lg:text-lg text-fiber-grey-900 bg-yellow rounded-md whitespace-nowrap"
        >
          {acceptLabel}
        </button>
      </div>
    </div>
  );
}
