import React from 'react';
import Rollbar from 'rollbar';

const getRollbarInstance = (): Rollbar => {
  const env = process.env.GATSBY_ROLLBAR_ENV;
  if (rollbarEnabled()) {
    return new Rollbar({
      accessToken: process.env.GATSBY_ROLLBAR_ACCESS_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      captureIp: true,
      payload: {
        environment: env,
        client: {
          javascript: {
            source_map_enabled: true,
            code_version: process.env.COMMIT_REF,
            guess_uncaught_frames: true,
          },
        },
      },
    });
  }
  return new Rollbar({
    enabled: false,
  });
};

export const logInfo = (message: string): void => {
  const rollbar = getRollbarInstance();
  if (rollbar) {
    rollbar.info(message);
  }
};

export const error = (message: string): void => {
  const rollbar = getRollbarInstance();
  if (rollbar) {
    rollbar.error(message);
  }
};

class RollbarComponent extends React.Component<unknown, { rollbar: Rollbar }> {
  constructor(props: unknown) {
    super(props);
    if (rollbarEnabled()) {
      this.state = {
        rollbar: getRollbarInstance(),
      };
    }
  }

  render(): null {
    return null;
  }
}

const rollbarEnabled = (): boolean => {
  const env = process.env.GATSBY_ROLLBAR_ENV;
  if (env === 'production' || env === 'deploy-preview' || env === 'staging') {
    return true;
  }

  return false;
};

export default RollbarComponent;
